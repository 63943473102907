"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WarningIcon = void 0;
const React = __importStar(require("react"));
function WarningIcon(props) {
    return (React.createElement("svg", Object.assign({ width: "22", height: "19", fill: "none", viewBox: "0 0 21 18" }, props),
        React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M11.359.49a1 1 0 00-1.72 0l-9.497 16a1 1 0 00.86 1.51h18.994a1 1 0 00.86-1.51L11.36.49zm-1.027 12.389h.343l.527-4.068c.152-1.168.228-1.837.228-2.008 0-.308-.076-.57-.228-.786-.153-.222-.375-.334-.668-.334-.305 0-.548.106-.73.317-.175.21-.263.484-.263.82 0 .228.1 1.043.299 2.444l.492 3.615zm.659 1.12a.974.974 0 00-.5-.129.931.931 0 00-.686.282.895.895 0 00-.281.667c0 .296.102.53.307.7.211.166.43.248.66.248a.928.928 0 00.491-.128c.147-.085.264-.197.352-.333a.91.91 0 00.131-.487.866.866 0 00-.131-.479.925.925 0 00-.343-.342z", clipRule: "evenodd" })));
}
exports.WarningIcon = WarningIcon;
