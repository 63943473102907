"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Toast = void 0;
const React = __importStar(require("react"));
const components_1 = require("@codesandbox/components");
const state_1 = require("../state");
const ErrorIcon_1 = require("./icons/ErrorIcon");
const SuccessIcon_1 = require("./icons/SuccessIcon");
const WarningIcon_1 = require("./icons/WarningIcon");
const InfoIcon_1 = require("./icons/InfoIcon");
const elements_1 = require("./elements");
const getColor = (colors, status) => colors[status];
const getIcon = (status) => {
    switch (status) {
        case state_1.NotificationStatus.ERROR:
            return ErrorIcon_1.ErrorIcon;
        case state_1.NotificationStatus.WARNING:
            return WarningIcon_1.WarningIcon;
        case state_1.NotificationStatus.SUCCESS:
            return SuccessIcon_1.SuccessIcon;
        case state_1.NotificationStatus.NOTICE:
            return InfoIcon_1.InfoIcon;
        default:
            return InfoIcon_1.InfoIcon;
    }
};
function Toast({ toast, removeToast, getRef, colors, Button }) {
    const { notification: { actions, title, message, status }, } = toast;
    const Icon = getIcon(status);
    const fullWidth = { width: '100%' };
    const action = (type) => {
        if (actions) {
            if (Array.isArray(actions[type])) {
                return actions[type][0];
            }
            return actions[type];
        }
        return null;
    };
    return (React.createElement(elements_1.Container
    // @ts-ignore
    , { 
        // @ts-ignore
        ref: getRef, marginBottom: 2 },
        React.createElement(elements_1.ColorLine, { bg: getColor(colors, status) }),
        React.createElement(elements_1.InnerWrapper, { padding: 4 },
            React.createElement(components_1.Element, { style: fullWidth },
                React.createElement(components_1.Stack, { style: fullWidth },
                    React.createElement(components_1.Element, { style: fullWidth },
                        React.createElement(components_1.Stack, { marginBottom: title && message ? 3 : 0, align: "center", gap: 2 },
                            React.createElement(components_1.Stack, { style: { color: getColor(colors, status) } },
                                React.createElement(Icon, null)),
                            React.createElement(components_1.Text, { style: {
                                    fontWeight: 500,
                                } }, title || message)),
                        title && (React.createElement(components_1.Text, { size: 3, block: true }, message))),
                    React.createElement(elements_1.StyledCrossIcon, { onClick: () => removeToast(toast.id) })),
                React.createElement(components_1.Element, null, actions && (React.createElement(components_1.Stack, { marginTop: 3, gap: 2, justify: "flex-end" },
                    action('secondary') && (React.createElement(elements_1.TertiaryButton, { variant: "secondary", onClick: () => {
                            if (action('secondary').hideOnClick) {
                                removeToast(toast.id);
                            }
                            action('secondary').run();
                        } }, action('secondary').label)),
                    action('primary') && (React.createElement(Button, { variant: "secondary", style: {
                            width: 'auto',
                        }, onClick: () => {
                            // By default we hide the notification on clicking action("primary") buttons
                            if (action('primary').hideOnClick !== false) {
                                removeToast(toast.id);
                            }
                            action('primary').run();
                        } }, action('primary').label)))))))));
}
exports.Toast = Toast;
