"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.messages = void 0;
var messages;
(function (messages) {
    messages["INITIALIZE"] = "initialize_tests";
    messages["ADD_FILE"] = "add_file";
    messages["REMOVE_FILE"] = "remove_file";
    messages["FILE_ERROR"] = "file_error";
    messages["TOTAL_TEST_START"] = "total_test_start";
    messages["TOTAL_TEST_END"] = "total_test_end";
    messages["TEST_START"] = "test_start";
    messages["TEST_END"] = "test_end";
    messages["DESCRIBE_START"] = "describe_start";
    messages["DESCRIBE_END"] = "describe_end";
    messages["ADD_TEST"] = "add_test";
})(messages = exports.messages || (exports.messages = {}));
