"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.colors = void 0;
exports.colors = {
    white: '#ffffff',
    grays: {
        100: '#fff9f9',
        200: '#e6e6e6',
        300: '#999999',
        400: '#757575',
        500: '#343434',
        600: '#242424',
        700: '#151515',
        800: '#111111',
        900: '#040404',
    },
    blues: {
        300: '#6CC7F6',
        500: '#3793E0',
        600: '#0971f1',
        700: '#535BCF',
        900: '#021D3E',
    },
    reds: {
        200: '#EB455A',
        300: '#FF453A',
        500: '#E1270E',
    },
    green: '#5BC266',
    purple: '#BF5AF2',
    yellow: '#FBCC43',
    orange: '#f69935',
};
