"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InfoIcon = void 0;
const React = __importStar(require("react"));
function InfoIcon(props) {
    return (React.createElement("svg", Object.assign({ width: "22", height: "22", fill: "none", viewBox: "0 0 22 22" }, props),
        React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M11 22c6.075 0 11-4.925 11-11S17.075 0 11 0 0 4.925 0 11s4.925 11 11 11zm1.675-14.616H8v1.808h2.444V14.2H8V16h7v-1.8h-2.325V7.384zM10.373 4.71c-.058.143-.087.3-.087.47 0 .17.029.329.087.477.064.144.15.268.262.375.111.1.243.18.397.238.159.059.333.088.524.088.391 0 .7-.109.928-.326.233-.223.35-.507.35-.853 0-.345-.117-.626-.35-.844-.227-.223-.537-.334-.928-.334-.19 0-.365.03-.524.088-.154.058-.286.14-.397.246a1.06 1.06 0 00-.262.375z", clipRule: "evenodd" })));
}
exports.InfoIcon = InfoIcon;
