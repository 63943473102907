"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TertiaryButton = exports.ColorLine = exports.Container = exports.InnerWrapper = exports.StyledCrossIcon = exports.NotificationContainer = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const components_1 = require("@codesandbox/components");
const CrossIcon_1 = require("./icons/CrossIcon");
exports.NotificationContainer = styled_components_1.default.div `
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 41;
`;
exports.StyledCrossIcon = styled_components_1.default(CrossIcon_1.CrossIcon) `
  transition: 0.3s ease color;
  cursor: pointer;

  &:hover {
    path {
      opacity: 0.4;
    }
  }
`;
exports.InnerWrapper = styled_components_1.default(components_1.Stack) `
  ${({ theme }) => styled_components_1.css `
    width: 100%;
    border: 1px solid ${theme.colors.grays[600]};
    background: ${theme.colors.grays[700]};
    border-top-right-radius: ${theme.radii.medium}px;
    border-bottom-right-radius: ${theme.radii.medium}px;
  `}
`;
exports.Container = styled_components_1.default(components_1.Stack) `
  ${({ theme }) => styled_components_1.css `
    box-sizing: border-box;
    box-shadow: ${theme.shadows[2]};
    border-radius: ${theme.radii.medium}px;
    position: relative;
    font-size: ${theme.fontSizes[3]}px;
    color: ${theme.colors.white};
    width: 450px;
    overflow: hidden;
  `}
`;
exports.ColorLine = styled_components_1.default(components_1.Element) `
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: ${props => props.bg};
  width: 4px;
`;
exports.TertiaryButton = styled_components_1.default(components_1.Button) `
  ${({ theme }) => styled_components_1.css `
    width: auto;
    background: transparent;
    border: 1px solid ${theme.colors.grays[600]};
  `}
`;
