"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationState = exports.NotificationChange = exports.NotificationStatus = void 0;
const uuid = __importStar(require("uuid"));
const events_1 = require("./utils/events");
var NotificationStatus;
(function (NotificationStatus) {
    NotificationStatus[NotificationStatus["NOTICE"] = 0] = "NOTICE";
    NotificationStatus[NotificationStatus["SUCCESS"] = 1] = "SUCCESS";
    NotificationStatus[NotificationStatus["WARNING"] = 2] = "WARNING";
    NotificationStatus[NotificationStatus["ERROR"] = 3] = "ERROR";
})(NotificationStatus = exports.NotificationStatus || (exports.NotificationStatus = {}));
var NotificationChange;
(function (NotificationChange) {
    NotificationChange[NotificationChange["ADD"] = 0] = "ADD";
    NotificationChange[NotificationChange["CHANGE"] = 1] = "CHANGE";
})(NotificationChange = exports.NotificationChange || (exports.NotificationChange = {}));
class NotificationState {
    constructor() {
        this._onNotificationUpdated = new events_1.Emitter();
        this._onNotificationRemoved = new events_1.Emitter();
        this.onNotificationUpdated = this._onNotificationUpdated.event;
        this.onNotificationRemoved = this._onNotificationRemoved.event;
        this.notifications = new Map();
    }
    getNotifications() {
        return this.notifications;
    }
    addNotification(notification) {
        const id = notification.id || uuid.v4();
        this.notifications.set(id, notification);
        this._onNotificationUpdated.emit({
            type: NotificationChange.ADD,
            id,
            notification,
        });
        return id;
    }
    updateNotification(id, notification) {
        this.notifications.set(id, notification);
        this._onNotificationUpdated.emit({
            type: NotificationChange.CHANGE,
            id,
            notification,
        });
    }
    removeNotification(id) {
        if (this.notifications.has(id)) {
            this.notifications.delete(id);
            this._onNotificationRemoved.emit({
                id,
            });
        }
    }
}
exports.NotificationState = NotificationState;
